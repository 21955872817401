import React from 'react';
import PropTypes from 'prop-types';
import SEO from './seo';

const Page = ({ children, title }) => (
  <>
    <SEO title={title} />
    <div className="page">
      <div className="page-container">
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  </>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
